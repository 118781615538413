

















































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { namespace, Action, State } from 'vuex-class'
import { Route } from 'vue-router'
import { mapActions, mapState } from 'vuex'
import PageHeader from '@/components/common/PageHeader.vue'
import { IMiniAppItem } from '@/types/common.d.ts'
import debounce from 'lodash/debounce';

// 为了新的客户设置domain whitelist 方便
const requestdomain_production = [
  "https://api.walkthechat.com",
  "https://fundebug.com",
  "https://piwik.walkthechat.com"
]
const requestdomain_staging = [
  "https://api.staging.walkthechat.com",
  "https://api.development.walkthechat.com",
  "https://fundebug.com",
  "https://piwik.walkthechat.com"
]
const uploaddomain_production = [
  "https://api.walkthechat.com"
]
const uploaddomain_staging = [
  "https://api.staging.walkthechat.com",
  "https://api.development.walkthechat.com"
]

@Component({
  components: {
    'page-header': PageHeader
  },
  methods: {
    ...mapActions([
      'fetchMiniPrograms',
      'uploadMiniappCode',
      'submitMiniappAudit',
      'releaseMiniapp',
      'appendDeployHistory',
      'submitDomainSetting',
      'fetchTemplates',
      'experienceQR',
      'manuallyRefreshAuditStatus'
    ])
  }
})
export default class Deploy extends Vue {

  fetchMiniPrograms!: (params: {limit?: number, page?: number, query?: string, keepFailed?: boolean}) => Array<IMiniAppItem>
  uploadMiniappCode!: (payload: any) => any
  submitMiniappAudit!: (projectId: string) => any
  releaseMiniapp!: (projectId: string) => any
  appendDeployHistory!: (payload: any) => any
  fetchDomainSetting!: (projectId: string) => any
  submitDomainSetting!: (payload: any) => any
  fetchTemplates!: () => any
  experienceQR!: (projectId: string) => any
  manuallyRefreshAuditStatus: (projectIds: Array<string>) => any

  private limit: number = 15
  private page: number = 1

  private isLoading: boolean = true
  private tableLoading: boolean = true
  private searchingQuery: boolean = false
  private currentProjectId: string = ''
  private showExperienceCode: boolean = false
  private experienceQRCode: string = ''
  private searchText: string = ''
  private currentTab: string = 'all'

  @State(state => state.deployment.mpList) mpList: any
  @State(state => state.deployment.miniProgramCount) count: any
  @State(state => state.domainSetting.domainSetting) domainSetting: any
  @State(state => state.templates.templates) templates: any
  @State(state => state.i18n.locale) locale: string

  // @Watch ('searchText') 
  // searchTextChange (val: string) {
  //   if (!val) {
  //     this.pageChanged(0)
  //   }
  // }

  async pageChanged (page?: number) {
    this.tableLoading = true
    this.page = page || 1
    this.$nextTick(() => {
      this.fetchObjects()
    })
  }

  // selectMiniProgram (params: any) {
  //   this.$router.push({name: 'mini-programs', params: {id: params.id}})
  // }

  async tapExperience (projectId: string) {
    const {imageBase64String} = await this.experienceQR(projectId)
    this.experienceQRCode = `data:image/jpeg;base64,${imageBase64String}`
    this.showExperienceCode = true
  }

  async tapUploadCode (project: any) {
    this.currentProjectId = project.id

    this.$store.dispatch('domainSetting/fetchDomainSetting', this.currentProjectId).then(async res => {

      if (this.domainSetting.requestdomain && this.domainSetting.requestdomain.length === 0) {
        const params = {
          requestdomain: process.env.VUE_APP_SERVER == 'production' ? requestdomain_production : requestdomain_staging,
          uploaddomain: process.env.VUE_APP_SERVER == 'production' ? uploaddomain_production : uploaddomain_staging
        }
        await this.$store.dispatch('domainSetting/submitDomainSetting', {params, projectId: project.id})
      }
      await this.fetchTemplates()

      if (this.templates && this.templates.length > 0) {
        await this.uploadMiniappCode({ 
          templateId: this.templates[0].template_id,
          userVersion: this.templates[0].user_version,
          userDesc: this.templates[0].user_desc,
          projectId: project.id
        })
        this.submitMiniappAudit(project.id).then((res: any) => {
          const params = {
            deployHistory: {
              auditInfo: {
                status: '2',
                reason: ''
              },
              submitDate: new Date()
            },
            projectId: project.id
          }
          this.appendDeployHistory(params)
          this.$toast.success('Sumbit successfully', {decuration: 2000})
        }).catch((err: any) => {
          this.$toast.error(err.errmsg, {decuration: 3000})
        }).finally(()=> {
          this.currentProjectId = null
        })
      } else {
        this.currentProjectId = null
      }
    })
  }

  closeExperience () {
    this.showExperienceCode = false
    this.experienceQRCode = ''
  }

  tapReleaseMiniapp (miniProgram: any) {
    if(miniProgram.releaseDate) {
      this.releaseMiniapp(miniProgram.project.id).then(() => {
        miniProgram.project.releaseDate = this.$moment().format("YYYY-MM-DD HH:mm:ss")
        this.$toast.success('Release successfully', {decuration: 2000})
      }).catch((err: any) => {
        this.$toast.error(err.errmsg, {decuration: 2000})
      })
    } else {
      this.$router.push({ name: 'mini-programs-id-release', params: { id: miniProgram.id }})
    }
  }

  async tapRefresh () {
    const projectIds = this.mpList.map((item: IMiniAppItem) => item.projectId)
    const data = await this.manuallyRefreshAuditStatus(projectIds)
    console.log(data)
  }

  async search (query: string) {
    this.page = 1
    this.searchText = query
    this.searchingQuery = true
    this.tableLoading = true
    this.$router.push({query: {q: this.searchText}})
    this.$nextTick(() => {
      this.fetchObjects()
    })
  }

  submitQuery(query: string) {
    if(query && query.length > 0) {
      this.changeQuery(query)
    } else {
      this.search(query)
    }
  }

  private debounceSearch = debounce(
      (q: string) => this.search(q),
      500,
      true
  );

  changeQuery(query: string) {
    this.debounceSearch(query)
  }
  async fetchObjects() {
    await this.fetchTemplates()

    try {
      if(this.currentTab == 'failed') {
        await this.fetchMiniPrograms({page: 1, limit: 1000, query: this.searchText, keepFailed: true})
      } else {
        await this.fetchMiniPrograms({page: this.page, limit: this.limit, query: this.searchText})
      }
      this.tableLoading = false
      this.searchingQuery = false
    } catch (err) {
      this.$toast.error(err.msg, {decuration: 3000})
    }
  }

  async mounted () {
    if(this.$route.query.q) {
      this.searchText = this.$route.query.q as string
    }
    if(this.$route.query.page) {
      this.page = Number(this.$route.query.page)
    }
    if(this.$route.query.current) {
      this.currentTab = this.$route.query.current as string
    }
  }

  changeSelect(name: string) {
    this.page = 1
    this.tableLoading = true
    this.currentTab = name

    this.$nextTick(() => {
      this.fetchObjects()
    })
  }

  canRelease(item: any) {
    try {
    if(item.deploys[0].auditInfo.status == 0
      //&& item.deploys[0].codeInfo.templateId == this.templates[0].template_id
      && (!item.releaseDate || item.releaseDate < item.deploys[0].modified)) {
      return true
    } else {
      return false
      // return (!item.deploys || !item.deploys[0] || (item.deploys && item.deploys[0] && this.templates[0] && item.deploys[0].codeInfo.templateId == this.templates[0].template_id && item.deploys[0].auditInfo.status == 0 && item.releaseDate > item.deploys[0].modified )) ? true : false
    }
    } catch (err) {
      return false
    }
  }
  canBeReleased(item: any) {
    return (!item.deploys || !item.deploys[0] || (item.deploys && item.deploys[0] && this.templates[0] && item.deploys[0].codeInfo.templateId == this.templates[0].template_id && item.deploys[0].auditInfo.status == 0 && item.releaseDate > item.deploys[0].modified )) ? true : false
  }

}
